export function kNumToDiaph(knum) {
  console.log(">>> Diaph Num : $knum");
  if (knum === 0) {
    return 0;
  }

  const exponent = Math.log(knum) / Math.log(Math.sqrt(2));

  var res = Math.pow(Math.sqrt(2), Math.floor(exponent));
  res = roundFloorOne(res);
  res = fixDiaphValue(res);
  return res;
}

export function kNumToDec(knum) {
  if (knum === 0) {
    return 0;
  }

  const lgK = Math.log(knum);
  const lgSqr = Math.log(Math.sqrt(2));
  const flr = Math.floor(lgK / lgSqr);
  let res = lgK / lgSqr - flr;
  res = roundOne(res);
  if (res >= 1) {
    res = 0.9;
  }
  return parseInt(res * 10);
}

export function roundOne(value) {
  if (value === 0) {
    return 0.0;
  }
  return Math.round(10 * value) / 10;
}

export function fixDiaphValue(value) {
  if (value === 11.3) {
    return 11;
  }
  if (value === 22.6) {
    return 22;
  }
  if (value === 45.2) {
    return 45;
  }
  if (value === 90.5) {
    return 90;
  }
  return value;
}

export function roundFloorOne(value) {
  if (value === 0) {
    return 0.0;
  }
  return Math.floor(10 * value) / 10;
}
