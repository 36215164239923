// import PropTypes from 'prop-types';
// material-ui
import { Grid, Grow, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Gelatins() {
  // const [ state, setState ] = React.useState(initialValue);
  const predilux = useSelector((s) => s.predilux);
  const classes = useStyles(styles);

  return (
    <Tooltip title="Gelatins">
      <Grid container>
        {predilux.selectedGelatins?.length === 0 ? (
          <Grid item className={classes.empty}>
            <Typography>No Gelatins</Typography>
          </Grid>
        ) : (
          []
        )}
        {predilux.selectedGelatins?.map((g) => (
          <Grow in key={g.id}>
            <Grid
              item
              style={{
                background: g.color,
                padding: 16,
                height: 100,
                width: 160,
                borderRadius: 16,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textShadow: "",
                marginRight: 8,
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  textShadow: "0px 0px 2px rgba(0,0,0,0.8)",
                }}
              >
                <Typography variant="body2">{g.name}</Typography>
                <Typography variant="body2">
                  {g.brand} - {g.ref}
                </Typography>
              </div>
            </Grid>
          </Grow>
        ))}
      </Grid>
    </Tooltip>
  );
}
