export default (theme) => ({
  container: {
    padding: 5,
    background: "rgba(255,255,255,0.3)",
    borderRadius: 16,
  },
  card: {
    padding: 8,
    background: "#ffb300",
    borderRadius: 16,
    minHeight: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 16,
    position: "relative",
  },
  cardLocked: {
    padding: 8,
    background: "rgba(255,255,255,0.6)",
    borderRadius: 16,
    minHeight: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 16,
    position: "relative",
  },
});
