// import PropTypes from 'prop-types';
// material-ui
import BallonOn from "assets/balloon-on.png";
import BallonOff from "assets/balloon.png";
import FluoOn from "assets/fluo-on.png";
import FluoOff from "assets/fluo.png";
import HmiOn from "assets/hmi-on.png";
import HmiOff from "assets/hmi.png";
import LedOn from "assets/led-on.png";
import LedOff from "assets/led.png";
import TungstenOn from "assets/tungsten-on.png";
import TungstenOff from "assets/tungsten.png";
import React from "react";

const imgSrc = {
  "balloon-on": BallonOn,
  "balloon-off": BallonOff,

  "fluo-on": FluoOn,
  "fluo-off": FluoOff,

  "hmi-on": HmiOn,
  "hmi-off": HmiOff,

  "led-on": LedOn,
  "led-off": LedOff,

  "tungsten-on": TungstenOn,
  "tungsten-off": TungstenOff,
};

export default function SourceIcon({ type, on, size = 40 }) {
  return (
    <>
      <img
        height={size}
        alt={type}
        src={imgSrc[`${type}-${on ? "on" : "off"}`]}
      />
    </>
  );
}
