// import PropTypes from 'prop-types';
// material-ui
import { Grid, Grow, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CameraSettings() {
  // const [ state, setState ] = React.useState(initialValue);
  const predilux = useSelector((s) => s.predilux);
  const classes = useStyles(styles);

  console.log(predilux);

  return (
    <Tooltip title="Camera Settings">
      <div className={classes.container}>
        <Typography variant="body2">
          <i class="fa-solid fa-aperture p-right"></i>Camera Settings
        </Typography>
        <Grid container spacing={8} alignContent="center" justify="center">
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="overline">FPS</Typography>
            <Grow in key={predilux.fps}>
              <Typography variant="h5">{predilux.fps}</Typography>
            </Grow>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="overline">ISO</Typography>
            <Grow in key={predilux.iso}>
              <Typography variant="h5">{predilux.iso}</Typography>
            </Grow>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="overline">ANGLE</Typography>
            <Grow in key={predilux.angle}>
              <Typography variant="h5">{predilux.angle}</Typography>
            </Grow>
          </Grid>
        </Grid>
      </div>
    </Tooltip>
  );
}
