import { Grid, Icon, Tooltip, Typography } from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import lightmeter from "assets/lightmeter.png";
import { kNumToDec, kNumToDiaph } from "helpers/predilux/predilux";
import SourceIcon from "hooks/SourceIcon";
import React from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Results() {
  const classes = useStyles(styles);
  const predilux = useSelector((s) => s.predilux);

  console.log(predilux);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} style={{ textAlign: "center" }}>
        <SourceIcon
          type={predilux.selectedSource?.type ?? "tungsten"}
          on
          size={70}
        />
        <Tooltip title="Distance from Camera">
          <div
            className={
              predilux.distanceLock ? classes.cardLocked : classes.card
            }
          >
            {predilux.distanceLock ? (
              <Icon
                style={{ position: "absolute", top: 10, right: 10 }}
                class="fa-solid fa-lock"
              />
            ) : (
              []
            )}
            <Typography variant="h5">{predilux.distance} m</Typography>
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={6} style={{ textAlign: "center" }}>
        <img src={lightmeter} alt={"lightmeter"} />
        <Tooltip title="Calculated Keylight">
          <div
            className={
              !predilux.distanceLock ? classes.cardLocked : classes.card
            }
          >
            {!predilux.distanceLock ? (
              <Icon
                style={{ position: "absolute", top: 10, right: 10 }}
                class="fa-solid fa-lock"
              />
            ) : (
              []
            )}
            <Typography variant="h5">
              {kNumToDiaph(predilux.knum)} <sup>{kNumToDec(predilux.knum)}</sup>
            </Typography>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
