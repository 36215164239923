// import PropTypes from 'prop-types';
// material-ui
import { Divider, Grid, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SourceIcon from "hooks/SourceIcon";
import React from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Source() {
  // const [ state, setState ] = React.useState(initialValue);
  const predilux = useSelector((s) => s.predilux);
  const classes = useStyles(styles);

  function spotDisplayValue(focalisationValue) {
    if (focalisationValue === 0) {
      return "Flood";
    }
    return `${Math.round(focalisationValue * 100)}% Spot`;
  }

  function getSourceSettingGlance() {
    const source = predilux.selectedSource;
    const grids = source?.gridValues ?? [];
    const colorTemps = source?.colorTemps ?? [];
    const lenses = source?.familyValues ?? [];

    console.log("Source: ", source);

    return (
      <Grid container spacing={2}>
        {source?.focusable ?? false ? (
          <Grid item>
            <span className={classes.chip}>{`Focus: ${spotDisplayValue(
              predilux.focalisationValue
            )}`}</span>
          </Grid>
        ) : (
          []
        )}
        {(source?.dimmable && predilux.dimValue) ?? false ? (
          <Grid item>
            <span className={classes.chip}>{`Intensity: ${
              predilux.dimValue * 100
            }%`}</span>
          </Grid>
        ) : (
          []
        )}
        {colorTemps.length > 1 ? (
          <Grid item>
            <span className={classes.chip}>{`Color Temp: ${
              predilux.selectedColorTemp?.name ?? "none"
            }`}</span>
          </Grid>
        ) : (
          []
        )}
        {grids.length > 1 ? (
          <Grid item>
            <span className={classes.chip}>{`Grid: ${
              predilux.selectedGrid?.name ?? "none"
            }`}</span>
          </Grid>
        ) : (
          []
        )}
        {lenses.length > 1 ? (
          <Grid item>
            <span className={classes.chip}>{`Lens: ${
              predilux.selectedLens?.name ?? "none"
            }`}</span>
          </Grid>
        ) : (
          []
        )}
      </Grid>
    );
  }

  return (
    <Tooltip title="Selected Source">
      <div
        className={predilux.selectedSource ? classes.container : classes.empty}
      >
        {predilux.selectedSource ? (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <SourceIcon type={predilux.selectedSource.type} on />
              <div style={{ paddingLeft: 16 }}>
                <Typography variant="h5">
                  {predilux.selectedSource.name}
                </Typography>
                <Typography variant="body2">
                  {predilux.selectedSource.power}W
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ background: "rgba(255,255,255,0.3)" }} />
            </Grid>
            <Grid item>
              <Grid container>{getSourceSettingGlance()}</Grid>
            </Grid>
          </Grid>
        ) : (
          <Typography>No Source</Typography>
        )}
      </div>
    </Tooltip>
  );
}
