// import PropTypes from 'prop-types';
// material-ui
import { CircularProgress, Grid, Grow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import appName from "assets/appName.png";
import icon from "assets/predilux_shadow.png";
import CameraSettings from "hooks/CameraSettings";
import Gelatins from "hooks/Gelatins";
import Results from "hooks/Results";
import Source from "hooks/Source";
import StoreBadges from "hooks/StoreBadges";
import React from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);
export default function App({ loading }) {
  const predilux = useSelector((s) => s.predilux);
  const classes = useStyles(styles);

  if (loading) {
    return (
      <div className={classes.root}>
        <div style={{ textAlign: "center" }}>
          <CircularProgress color="white" />
          <Typography style={{ fontFamily: "Futura-Bold" }} variant="h6">
            LOADING...
          </Typography>
        </div>
      </div>
    );
  }

  if (!predilux) {
    return (
      <div className={classes.root}>
        <div style={{ textAlign: "center" }}>
          <i class="fad fa-times" style={{ fontSize: 48 }}></i>
          <Typography style={{ fontFamily: "Futura-Bold" }} variant="h6">
            Predilux not found!
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root} style={{ background: "black" }}>
      <div style={{ textAlign: "center" }}>
        <img src={icon} height={120} />
        <br />
        <img src={appName} height={20} />
      </div>
      <div
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid container spacing={2} style={{ maxWidth: 600 }}>
          <Grid item>
            <Typography variant="h6">{predilux.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <CameraSettings />
          </Grid>
          <Grid item xs={12}>
            <Grow in key={predilux.selectedSource?.id}>
              <Source />
            </Grow>
          </Grid>
          <Grid item xs={12}>
            <Gelatins />
          </Grid>
          <Grid item xs={12}>
            <Results />
          </Grid>
        </Grid>
      </div>
      <StoreBadges secondaryColor={"#ffffff"} primaryColor={"#ffffff"} />
    </div>
  );
}
