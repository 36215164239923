// material-ui
import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
// custom components
import Root from "components/Root";
import React from "react";
import { useSelector } from "react-redux";
import theme from "themes/theme";
import "./App.css";

function App() {
  const app = useSelector((state) => state.app);

  let muiTheme = createMuiTheme({
    palette: { ...theme, type: "dark" },
    paper: {
      boxShadow: "none",
    },
    typography: {
      button: {
        textTransform: "none",
      },
      h6: {
        fontWeight: 700,
      },
      useNextVariants: true,
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  muiTheme = responsiveFontSizes(muiTheme);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <Root />
    </MuiThemeProvider>
  );
}

export default App;
