// material-ui
import { setPredilux } from "actions";
// components
import App from "components/App";
import { getJsonFromUrl } from "helpers";
import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

export default function Root() {
  const [loading, setLoading] = React.useState(true);
  const [vh, setVh] = React.useState(false);
  const dispatch = useDispatch();
  const predilux = useSelector((s) => s.predilux);

  React.useEffect(() => {
    window.addEventListener("resize", refreshInnerHeight);
    listenToPredilux();
  }, []);

  const refreshInnerHeight = () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    setVh(vh);
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const listenToPredilux = () => {
    console.log("Listen To Predilux...");

    const urlParams = getJsonFromUrl(window.location);

    const query = window.firebase
      .firestore()
      .doc(`/lightningSetups/${urlParams.m}`);

    query.onSnapshot(
      (querySnapshot) => {
        const _predilux = querySnapshot.data();
        if (_predilux) dispatch(setPredilux(_predilux));
        setLoading(false);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  var title = "Loading...";

  if (predilux) {
    title = `Predilux`;
  } else {
    title = "Predilux not found!";
  }
  return (
    <>
      <Helmet>
        <meta name="theme-color" content={predilux?.rule?.color ?? "#ffffff"} />
        <title>{title}</title>
      </Helmet>
      <div
        style={{
          minHeight: "calc(var(--vh, 1vh) * 100)",
          width: "100vw",
          overflow: "scroll",
          display: "flex",
          position: "relative",
        }}
      >
        <App loading={loading} />
      </div>
    </>
  );
}
