export default (theme) => ({
  container: {
    padding: 8,
    background: "#ffb300",
    borderRadius: 16,
  },
  empty: {
    padding: 8,
    borderRadius: 16,
    border: "solid 1px white",
    minHeight: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  chip: {
    background: "rgba(255,255,255,0.6)",
    padding: "2px 4px",
    borderRadius: 4,
    color: "rgba(0,0,0,0.6)",
    fontSize: 14,
  },
});
