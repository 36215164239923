// import PropTypes from 'prop-types';
// material-ui
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppStoreBadgeWhite from "assets/AppStoreBadgeWhite.svg";
import GooglePlayBadgeWhite from "assets/GooglePlayBadgeWhite.svg";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function StoreBadges({ secondaryColor, primaryColor }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  return (
    <div
      className={classes.root}
      style={{
        color: secondaryColor,
      }}
    >
      <Typography variant="caption">
        <b>Shared with Predilux</b>
      </Typography>
      <br />
      <Grid container spacing={1} justify="center">
        <Grid item>
          <img src={AppStoreBadgeWhite} height="35px" alt="AppStoreBadge" />
        </Grid>
        <Grid item>
          <img src={GooglePlayBadgeWhite} height="35px" alt="GooglePlayBadge" />
        </Grid>
      </Grid>
    </div>
  );
}
