export default (theme) => ({
  container: {
    padding: 5,
  },
  empty: {
    borderRadius: 16,
    padding: 16,
    border: "solid 1px white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    width: 160,
    marginRight: 8,
  },
});
